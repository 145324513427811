import Button from './Button/Button'

const Confirm = ({ question, confirmFn, cancelFn, items }) =>
  <div className="overlay">
    <div className="overlay-modal">
      <p>{question}</p>

      {
        items &&
        <ul>
          {
            items.map((item, index) =>
              <li key={index}>{item}</li>
            )
          }
        </ul>
      }

      <div className="buttons">
        <Button text="Cancel" fn={cancelFn} />
        <Button text="Confirm" fn={confirmFn} classes="confirm" />
      </div>
    </div>
  </div>

export default Confirm