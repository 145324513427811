import './Breadcrumbs.scss'

const Breadcrumbs = ({ step, crumbs }) => {
  return (
    <div className="Breadcrumbs">
    {
      crumbs.map((crumb, index) => (
        <div key={index} className={`crumb${index === step ? ' current' : ''}`}>{crumb.text}</div>
      ))
    }
    </div>
  )
}

export default Breadcrumbs