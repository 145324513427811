import { useState, useEffect } from 'react'
import Icon from '../../elements/Icon/Icon'

import './UsersList.scss'

import Button from '../../elements/Button/Button'
import Confirm from '../../elements/Confirm'
import {
  API_URL,
  ERROR_MESSAGE,
  ERROR_DOING_ACTION_MESSAGE,
  SUBSCRIPTION_MONTHLY,
  SUBSCRIPTION_YEARLY,
  SUBSCRIPTION_INACTIVE
} from '../../../utils/constants'
import { getSubscriptionName } from '../../../utils/formats'
import {
  fetchData,
  fetchItems
} from '../../../utils/server'

const UsersList = ({ setUserId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [action, setAction] = useState('')
  const [actionOption, setActionOption] = useState('')
  const [showActionModal, setShowActionModal] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(0)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [filterUsername, setFilterUsername] = useState('')
  const [filterCompany, setFilterCompany] = useState('')
  const [filterDepartment, setFilterDepartment] = useState('')
  const [filterSubscriptionType, setFilterSubscriptionType] = useState('s')
  const [sortByUsername, setSortByUsername] = useState(1)
  const [sortBySubscription, setSortBySubscription] = useState(0)
  const [sortByCompany, setSortByCompany] = useState(0)
  const [sortByDepartment, setSortByDepartment] = useState(0)

  const handleFilterSubscriptionType = (e, subscription) => {
    if (e.target.checked) {
      setFilterSubscriptionType(subscription)
    }
  }

  const handleSelectedUser = (e, userId) => {
    if (e.target.checked) {
      if (userId > 0) {
        setSelectedUsers([...selectedUsers, userId])
      }
      else {

        setSelectedUsers(users.map(user => user.id))
      }
    }
    else {
      if (userId > 0) {
        setSelectedUsers(selectedUsers.filter(user => user !== userId))
      }
      else {
        setSelectedUsers([])
      }
    }
  }

  const doAction = (action, options = { userId: 0, type: '' }) => {
    if (options.userId > 0) {
      setSelectedUser(options.userId)
    }
    
    setAction(action)
    setActionOption(options.type)
    setShowActionModal(true)
  }

  const confirmAction = async () => {
    if (action) {
      let data
  
      try {
        let endpoint = ''
        const body = { usersId: selectedUser > 0 ? [selectedUser] : selectedUsers }

        if (actionOption === 'monthly' || actionOption === 'yearly') {
          endpoint = 'enable-subscriptions'

          body['subscriptionType'] = actionOption
        }
        else {
          endpoint = action === 'cancel' ? 'cancel-subscriptions' : 'delete-users'
        }
        
        data = await fetchData(
          `${API_URL}${endpoint}`,
          'POST',
          body
        )
  
        if (data?.message) {
          setSelectedUsers([])
          setIsLoading(true)
        }
        else {
          setErrorMsg(`${ERROR_DOING_ACTION_MESSAGE}${action} users`)
          setError(true)
        }
      }
      catch (error) {
        if (data?.error?.message) {
          setErrorMsg(data.error.message)
        }
        else {
          setErrorMsg(ERROR_MESSAGE)
        }
  
        setError(true)
      }
    }
    
    setSelectedUser(0)
    setShowActionModal(false)
  }

  const cancelAction = () => {
    setSelectedUser(0)
    setShowActionModal(false)
  }

  const setSort = (fn, fieldState, fieldName) => {
    setSortByUsername(0)
    setSortBySubscription(0)
    setSortByCompany(0)
    setSortByDepartment(0)

    fn(fieldState < 1 ? 1 : -1)

    setUsers(sortBy(users, fieldName, fieldState < 1 ? false : true))
  }

  const sortBy = (items, field, inverse = false) =>
    items.sort((a, b) =>
      inverse ?
        a[field] > b[field] ? 1 :
        b[field] > a[field] ? -1 :
        0
      :
        a[field] > b[field] ? -1 :
        b[field] > a[field] ? 1 :
        0
    )

  const getFilteredUsers = () =>
    users.filter(user =>
      user.username.toLowerCase().includes(filterUsername.toLowerCase()) &&
      user.company.toLowerCase().includes(filterCompany.toLowerCase()) &&
      user.department.toLowerCase().includes(filterDepartment.toLowerCase()) &&
      (
        (filterSubscriptionType === 's' && (user.subscription === SUBSCRIPTION_MONTHLY || user.subscription === SUBSCRIPTION_YEARLY)) ||
        (filterSubscriptionType === 'm' && user.subscription === SUBSCRIPTION_MONTHLY) ||
        (filterSubscriptionType === 'y' && user.subscription === SUBSCRIPTION_YEARLY) ||
        (filterSubscriptionType === 'i' && user.subscription === SUBSCRIPTION_INACTIVE) ||
        filterSubscriptionType === 'a'
      )
    )
  
  useEffect(() => {
    const getUsers = async () => {
      fetchItems(
        'users',
        data => data.users ? sortBy(data.users, 'username') : [],
        setUsers,
        setErrorMsg,
        setError
      )
    }

    if (isLoading) {
      getUsers()
      setIsLoading(false)
    }
  }, [isLoading])

  return (
    <div className="UsersList">
      <div className="filters">
        <p>Filter by:</p>

        <input type="text" placeholder="Username" onChange={e => setFilterUsername(e.target.value)} />
        <input type="text" placeholder="Company" onChange={e => setFilterCompany(e.target.value)} />
        <input type="text" placeholder="Department" onChange={e => setFilterDepartment(e.target.value)} />
        <div className="left">
          Subscription Type:
          <input type="radio" name="subscriptionType" value="s" defaultChecked={true} onChange={e => handleFilterSubscriptionType(e, 's')} />Subscribed
          <input type="radio" name="subscriptionType" value="m" onChange={e => handleFilterSubscriptionType(e, 'm')} />Monthly
          <input type="radio" name="subscriptionType" value="y" onChange={e => handleFilterSubscriptionType(e, 'y')} />Yearly
          <input type="radio" name="subscriptionType" value="i" onChange={e => handleFilterSubscriptionType(e, 'i')} />Inactive
          <input type="radio" name="subscriptionType" value="a" onChange={e => handleFilterSubscriptionType(e, 'a')} />All
        </div>

        <div className="buttons">
          {
            <Button text="Load Users" fn={() => setIsLoading(true)} />
          }
        </div>
      </div>

      <table className="results">
        <thead>
          <tr className="tbl-header">
            <th>
            {
              users.length > 0 &&
              <input type="checkbox" checked={selectedUsers.length === users.length} onChange={e => handleSelectedUser(e, 0)} />
            }
            </th>
            <th className={sortByUsername === 1 ? 'underline' : sortByUsername === -1 ? 'overline' : ''} onClick={() => setSort(setSortByUsername, sortByUsername, 'username')}>Username</th>
            <th className={sortBySubscription === 1 ? 'underline' : sortBySubscription === -1 ? 'overline' : ''} onClick={() => setSort(setSortBySubscription, sortBySubscription, 'subscription')}>Subscription</th>
            <th className={sortByCompany === 1 ? 'underline' : sortByCompany === -1 ? 'overline' : ''} onClick={() => setSort(setSortByCompany, sortByCompany, 'company')}>Company</th>
            <th className={sortByDepartment === 1 ? 'underline' : sortByDepartment === -1 ? 'overline' : ''} onClick={() => setSort(setSortByDepartment, sortByDepartment, 'department')}>Department</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {
          users.length > 0
          ?
          <>
            {
              getFilteredUsers().map((user, index) =>
                <tr key={index}>
                  <td><input type="checkbox" id={user.id} checked={selectedUsers.includes(user.id)} onChange={e => handleSelectedUser(e, user.id)} /></td>
                  <td>{user.username}</td>
                  <td>{getSubscriptionName(user.subscription)}</td>
                  <td>{user.company}</td>
                  <td>{user.department}</td>
                  <td>
                    <Icon icon="profile" title="More Details" size={20} color="white" className="pointer" onClick={() => setUserId(user.id)} />
                    {
                      user.subscription !== SUBSCRIPTION_INACTIVE
                      ?
                      <Icon icon="pause" title="Cancel Subscription" size={20} color="white" className="pointer" onClick={() => doAction('cancel', { userId: user.id })} />
                      :
                      <>
                        <Icon icon="play2" title="Enable Subscription Monthly" size={20} color="white" className="pointer" onClick={() => doAction('enable', { userId: user.id, type: 'monthly' })} />
                        <Icon icon="forward2" title="Enable Subscription Yearly" size={20} color="white" className="pointer" onClick={() => doAction('enable', { userId: user.id, type: 'yearly' })} />
                      </>
                    }
                    <Icon icon="user-minus" title="Delete User" size={20} color="white" className="pointer" onClick={() => doAction('delete', { userId: user.id })} />
                  </td>
                </tr>
              )
            }
          </>
          :
          <tr>
            <td colSpan="6" style={{ display: 'table-cell', textAlign: 'center' }}>No users found</td>
          </tr>
        }
        </tbody>
      </table>
            
      {
        selectedUsers.length > 0 &&
        <div className="buttons">
          {
            filterSubscriptionType === 'i' &&
            <>
              <Button text="Enable Monthly Subscriptions" fn={() => doAction('enable', { type: 'monthly' })} />
              <Button text="Enable Yearly Subscriptions" fn={() => doAction('enable', { type: 'yearly' })} />
            </>
          }
          {
            (filterSubscriptionType === 's' || filterSubscriptionType === 'm' || filterSubscriptionType === 'y') &&
            <Button text="Cancel Subscriptions" fn={() => doAction('cancel')} />
          }
          <Button text="Delete Users" fn={() => doAction('delete')} />
        </div>
      }

      <p>Total: {users.length} users{ getFilteredUsers().length < users.length && <> (showing { getFilteredUsers().length } by filters)</> }</p>

      { error && <p><span className="bold">Error:</span> <span>{errorMsg}</span></p> }

      { showActionModal &&
        <Confirm
          question={
            `Are you sure you want to ${action} ${
              selectedUser > 0 || selectedUsers.length === 1 ? 'this' : 'these'
            } ${
              action === 'cancel' ? 'subscription' :
              action === 'enable' ? `(${actionOption}) subscription` :
              'user'
            }${
              selectedUser > 0 || selectedUsers.length === 1 ? '' : 's'
            }?`
          }
          confirmFn={confirmAction}
          cancelFn={cancelAction}
          items={selectedUser > 0 ?
            [users.find(user => user.id === selectedUser).username]
            :
            selectedUsers.map(userId => users.find(user => user.id === userId).username)
          }
        />
      }
    </div>
  )
}

export default UsersList