export const handleChange = (e, fn) => fn(e.target.value)

export const validatePassword = password => {
  /*
  At least:
    8 characters long
    1 capital letter
    1 lowercase letter
    1 special character: @ $ ! % * ? &
    1 numeric character
  */
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  return re.test(password)
}

export const validateEmail = email => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}