import { useState } from 'react'

import './CreateItem.scss'

import ItemCompany from './Items/ItemCompany'
import ItemDepartment from './Items/ItemDepartment'
import Button from '../../elements/Button/Button'
import {
  API_URL,
  STEPS_DATA_IMPORTER,
  ERROR_MESSAGE,
  ERROR_POST_DATA_MESSAGE,
  ERROR_SOME_FIELDS_REQUIRED
} from '../../../utils/constants'
import { fetchData } from '../../../utils/server'
import { capitalize } from '../../../utils/strings'

const CreateItem = ({ steps, types, selections }) => {
  const { previousStep, step, goToStep } = steps;
  const { itemType, itemsType } = types;
  const { setSelectedItem, selectedParent = null } = selections;

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [validateFn, setValidateFn] = useState(null)

  const sendItem = async body => {
    let data

    try {
      data = await fetchData(
        selectedParent?.id ?
          `${API_URL}${itemsType}/${selectedParent.id}` :
          `${API_URL}${itemsType}`,
        'POST',
        body
      )

      if (data?.id && data?.name) {
        const newItem = {
          id: data.id,
          name: data.name
        }
        
        setSelectedItem(newItem)
        goToStep(step+1, step)
      }
      else {
        if (data?.error?.message) {
          setErrorMsg(data.error.message)
        }
        else {
          setErrorMsg(`${ERROR_POST_DATA_MESSAGE}${itemType}`)
        }
        
        setError(true)
      }
    }
    catch (error) {
      if (data?.error?.message) {
        setErrorMsg(data.error.message)
      }
      else {
        setErrorMsg(ERROR_MESSAGE)
      }

      setError(true)
    }
  }

  const validate = () => {
    const item = validateFn()

    if(item) {
      sendItem(item)
    }
    else {
      setErrorMsg(ERROR_SOME_FIELDS_REQUIRED)
      setError(true)
    }
  }

  return (
    <div className="CreateItem">
      <h2>Create New {capitalize(itemType)}</h2>

      <div className="wrapper">
        <p className="right">(*): Required fields</p>

        { step === STEPS_DATA_IMPORTER.CREATE_COMPANY && <ItemCompany setValidateFn={setValidateFn} /> }
        { step === STEPS_DATA_IMPORTER.CREATE_DEPARTMENT && <ItemDepartment setValidateFn={setValidateFn} /> }

        { error && <p><span className="bold">Error:</span> <span>{errorMsg}</span></p> }
        
        <div className="buttons">
          <Button text="Back" fn={() => goToStep(previousStep[previousStep.length-1])} classes="cancel" />
          <Button text="Next Step" fn={validate} />
        </div>
      </div>
    </div>
  )
}

export default CreateItem