import { useEffect, useState } from 'react'

import {
  handleChange,
  validateEmail
} from '../../../../utils/validations'

const ItemDepartment = ({ setValidateFn }) => {
  const [errorDepartmentName, setErrorDepartmentName] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)

  const [departmentName, setDepartmentName] = useState('')
  const [subscriber, setSubscriber] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    setValidateFn(() => () => {
      if (
        departmentName.length > 0 &&
        (
          email.length === 0 ||
          (
            email.length > 0 &&
            validateEmail(email)
          )
        )
      ) {
        const newDepartment = { name: departmentName }

        if (subscriber.length > 0) {
          newDepartment['subscriber'] = subscriber
        }
        if (email.length > 0) {
          newDepartment['email'] = email
        }
        if (phone.length > 0) {
          newDepartment['phone'] = phone
        }

        return newDepartment
      }
      else {
        setErrorDepartmentName(departmentName.length === 0)
        setErrorEmail(email.length > 0 && !validateEmail(email))

        return false
      }
    })
  }, [departmentName, subscriber, email, phone, setValidateFn])

  return (
    <>
      <label>Name (*)</label>
      <input type="text" className={errorDepartmentName ? 'field-error' : ''} placeholder="Enter Department name" onChange={e => handleChange(e, setDepartmentName)} />

      <label>Contact person</label>
      <input type="text" placeholder="Enter Contact person" onChange={e => handleChange(e, setSubscriber)} />

      <label>Email address</label>
      <input type="text" className={errorEmail ? 'field-error' : ''} placeholder="Enter Email address" onChange={e => handleChange(e, setEmail)} />

      <label>Phone number</label>
      <input type="text" placeholder="Enter Phone number" onChange={e => handleChange(e, setPhone)} />
    </>
  )
}

export default ItemDepartment