import openEye from '../../assets/images/open-eye.png'
import closeEye from '../../assets/images/close-eye.png'

const EyePassword = ({showPassword, setShowPassword}) => {
  const toggleShowPassword = () => setShowPassword(!showPassword)

  return (
    <img
      src={!showPassword ? closeEye : openEye}
      alt={!showPassword ? 'Show' : 'Hide'} className="icon-sm"
      style={{margin: '0 8px', cursor: 'pointer'}}
      onClick={toggleShowPassword}
    />
  )
}

export default EyePassword