import {
  API_URL,
  ERROR_MESSAGE,
  ERROR_GET_DATA_MESSAGE
} from './constants'

export const fetchData = async (url, method = 'GET', body = null) => {
  const request = {
    method,
    headers: { 'Content-Type': 'application/json' }
  }

  if (body) {
    request['body'] = JSON.stringify(body)
  }

  if (localStorage.getItem('token')) {
    request.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  }

  try {
    const response = await fetch(url, request)
    const data = await response.json()

    return data
  }
  catch (error) {
    return null
  }
}

export const fetchItems = async (endpoint, getFinalData, setData, setErrorMsg, setError, errorMsg = ERROR_GET_DATA_MESSAGE) => {
  let data

  try {
    data = await fetchData(`${API_URL}${endpoint}`)
    const finalData = getFinalData(data)
  
    if (finalData) {
      setData(finalData)
    }
    else {
      setErrorMsg(errorMsg)
      setError(true)
    }
  }
  catch (error) {
    if (data?.error?.message) {
      setErrorMsg(data.error.message)
    }
    else {
      setErrorMsg(ERROR_MESSAGE)
    }

    setError(true)
  }
}