export const API_URL = `${process.env.REACT_APP_URL_SERVER}/api/admin/`

export const STEPS_DATA_IMPORTER = {
  SELECT_USERS_TYPE: 0,
  CHOOSE_COMPANY: 1,
  CREATE_COMPANY: 2,
  CHOOSE_DEPARTMENT: 3,
  CREATE_DEPARTMENT: 4,
  IMPORT_USERS: 5,
  SUCCESS_IMPORT: 6
}

export const ERROR_MESSAGE = 'Something went wrong. Please try again later'
export const ERROR_LOGIN_MESSAGE = 'User and password are not valid'
export const ERROR_GET_DATA_MESSAGE = 'Data not received for '
export const ERROR_POST_DATA_MESSAGE = 'Data not sent for new '
export const ERROR_DOING_ACTION_MESSAGE = 'This action could not be performed: '
export const ERROR_SOME_FIELDS_REQUIRED = 'Some fields are required. Please fill in them'

export const SUBSCRIPTION_MONTHLY = '1m'
export const SUBSCRIPTION_YEARLY = '1y'
export const SUBSCRIPTION_INACTIVE = '0d'