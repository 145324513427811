import './SelectUsersType.scss'

import Button from '../../elements/Button/Button'
import { STEPS_DATA_IMPORTER } from '../../../utils/constants'

const SelectUsersType = ({ goToStep }) =>
  <div className="SelectUsersType">
    <h2>Select Users Type</h2>
    
    <div className="buttons">
      <Button text="Company users" fn={() => goToStep(STEPS_DATA_IMPORTER.CHOOSE_COMPANY, STEPS_DATA_IMPORTER.SELECT_USERS_TYPE)} />
      <p>- or -</p>
      <Button text="Individual users" fn={() => goToStep(STEPS_DATA_IMPORTER.IMPORT_USERS, STEPS_DATA_IMPORTER.SELECT_USERS_TYPE)} />
    </div>
    
    <p>(<span className="bold">WARNING:</span> Individual users choice will create users WITHOUT a linked company)</p>
  </div>

export default SelectUsersType