import { useState, useEffect } from 'react'

import './ChooseItem.scss'

import Button from '../../elements/Button/Button'
import { ERROR_GET_DATA_MESSAGE } from '../../../utils/constants'
import { fetchItems } from '../../../utils/server'
import { capitalize } from '../../../utils/strings'

const ChooseItem = ({ steps, types, selections }) => {
  const { previousStep, step, goToStep } = steps;
  const { itemType, itemsType } = types;
  const { selectedItem, setSelectedItem, selectedParent = null } = selections;

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [items, setItems] = useState([])

  useEffect(() => {
    const getItems = async () => {
      fetchItems(
        selectedParent?.id ? `${itemsType}/${selectedParent.id}` : itemsType,
        data => data[itemsType] ? data[itemsType] : [],
        setItems,
        setErrorMsg,
        setError,
        `${ERROR_GET_DATA_MESSAGE}${itemsType}`
      )
    }

    getItems()
  }, [itemsType, selectedParent])

  return (
    <div className="ChooseItem">
      { selectedParent?.name && <h2>{selectedParent.name} Company</h2> }
      
      <h3>Create New {capitalize(itemType)}</h3>

      <div className="buttons">
        <Button text="Next Step" fn={() => goToStep(step+1, step)} />
      </div>

      <p>- or -</p>
      
      <h3>Select Created {capitalize(itemType)}</h3>
      
      <select
        id={itemType}
        name={itemType}
        onChange={e => setSelectedItem({
          id: Number(e.target.value),
          name: e.target.options[e.target.selectedIndex].text
        })}
        value={selectedItem.id}
      >
        <option value="0">-- No {itemType} selected --</option>
        { items && items.map(item => <option key={item.id} value={item.id}>{item.name}</option> )}
      </select>
      
      { error && <p><span className="bold">Error:</span> <span>{errorMsg}</span></p> }

      <div className="buttons">
        { selectedItem.id > 0 && <Button text="Next Step" fn={() => goToStep(step+2, step)} /> }
      </div>
      <div className="buttons">
        <Button text="Back" fn={() => goToStep(previousStep[previousStep.length-1])} classes="cancel" />
      </div>
    </div>
  )
}

export default ChooseItem