import { useState } from 'react'
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize
} from 'react-papaparse'

import './ImportUsers.scss'

import Button from '../../elements/Button/Button'
import Loading from '../../elements/Loading'
import {
  API_URL,
  STEPS_DATA_IMPORTER,
  ERROR_MESSAGE,
  ERROR_POST_DATA_MESSAGE,
  SUBSCRIPTION_MONTHLY,
  SUBSCRIPTION_YEARLY
} from '../../../utils/constants'
import { fetchData } from '../../../utils/server'

const DEFAULT_REMOVE_HOVER_COLOR = '#A01919'
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40)

const ImportUsers = ({ steps, selections, setImportedData }) => {
  const { previousStep, goToStep } = steps;
  const { selectedCompany, selectedDepartment } = selections;

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(ERROR_MESSAGE)
  const [zoneHover, setZoneHover] = useState(false)
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR)
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const {CSVReader} = useCSVReader()

  const sendUsers = async () => {
    let data

    setIsLoading(true)

    try {
      data = await fetchData(
        `${API_URL}register-many`,
        'POST',
        { users }
      )

      if (data?.message) {
        setImportedData(data)
        goToStep(STEPS_DATA_IMPORTER.SUCCESS_IMPORT, STEPS_DATA_IMPORTER.IMPORT_USERS)
      }
      else {
        if (data?.error?.message) {
          setErrorMsg(data.error.message)
        }
        else {
          setErrorMsg(`${ERROR_POST_DATA_MESSAGE} users`)
        }
        
        setError(true)
      }
    }
    catch (error) {
      if (data?.error?.message) {
        setErrorMsg(data.error.message)
      }
      else {
        setErrorMsg(ERROR_MESSAGE)
      }

      setError(true)
    }

    setIsLoading(false)
  }

  const handleSubscriptionType = (e, email, subscription) => {
    if (e.target.checked) {
      setUsers(users.map(user => {
        if (user.email === email) {
          user.subscription = subscription
        }
        
        return user
      }))
    }
  }
  
  return (
    <div className="ImportUsers">
      <h2>Import Users</h2>

      <div className="wrapper-sm">
        { selectedCompany.name && <p className="left"><span className="bold">Company:</span> <span>{selectedCompany.name}</span></p> }
        { selectedDepartment.name && <p className="left"><span className="bold">Department:</span> <span>{selectedDepartment.name}</span></p> }
      </div>
      
      <h3>Upload CSV</h3>

      <CSVReader
        onUploadAccepted={results => {
          setUsers(results.data.slice(1).map(user => {
            const newUser = {
              email: user[0],
              password: user[1],
              firstname: user[2],
              lastname: user[3],
              subscription: SUBSCRIPTION_MONTHLY
            }

            if (selectedCompany.id) {
              newUser['company'] = selectedCompany.id
            }
            if (selectedDepartment.id) {
              newUser['department'] = selectedDepartment.id
            }
            
            return newUser
          }))
          setZoneHover(false)
        }}
        onDragOver={event => {
          event.preventDefault()
          setZoneHover(true)
        }}
        onDragLeave={event => {
          event.preventDefault()
          setZoneHover(false)
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
          Remove,
        }) =>
          <div {...getRootProps()} className={`zone${zoneHover ? ' zoneHover' : ''}`}>
          {
            acceptedFile
            ?
            <div className="file">
              <div className="info">
                <span className="size">{formatFileSize(acceptedFile.size)}</span>
                <span className="name">{acceptedFile.name}</span>
              </div>
              <div className="progressBar">
                <ProgressBar />
              </div>
              <div className="remove"
                {...getRemoveFileProps()}
                onMouseOver={event => {
                  event.preventDefault()
                  setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)
                }}
                onMouseOut={event => {
                  event.preventDefault()
                  setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)
                }}
              >
                <Remove color={removeHoverColor} />
              </div>
            </div>
            :
            'Drop CSV file here or click to upload'
          }
          </div>
        }
      </CSVReader>

      <h3>Preview Results</h3>
        
      {
        users.length > 0
        ?
        <>
          <table className="results">
            <thead>
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Subscription Type</th>
              </tr>
            </thead>
            <tbody>
            {
              users.map((user, index) =>
                <tr key={index}>
                  <td>{user.email}</td>
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td className="flexbox">
                    <div>
                      <input type="radio" name={`subsType${(index+1)}`} onChange={e => handleSubscriptionType(e, user.email, SUBSCRIPTION_MONTHLY)} defaultChecked={true} />Monthly
                    </div>
                    <div>
                      <input type="radio" name={`subsType${(index+1)}`} onChange={e => handleSubscriptionType(e, user.email, SUBSCRIPTION_YEARLY)} />Yearly
                    </div>
                  </td>
                </tr>
              )
            }
            </tbody>
          </table>

          <p>Total: {users.length} users</p>
          <p>(NOTE: By security reasons, imported passwords are not visible in this screen.)</p>

          { error && <p><span className="bold">Error:</span> <span>{errorMsg}</span></p> }
        </>
        :
        <p>No users</p>
      }

      <div className="buttons">
        <Button text="Back" fn={() => goToStep(previousStep[previousStep.length-1])} classes="cancel" />
        { users.length > 0 && <Button text="Create Users" fn={() => sendUsers()} /> }
      </div>

      { isLoading && <Loading text="Sending data..." /> }
    </div>
  )
}

export default ImportUsers