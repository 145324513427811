import { Link, useNavigate } from 'react-router-dom'

import './Nav.scss'

const Nav = ({signout}) => {
  const navigate = useNavigate()

  const logout = e => {
    e.preventDefault()
    localStorage.removeItem('token')
    signout()
    navigate('/')
  }

  return (
    <nav className="Nav">
      <Link to="/data-importer">Data Importer</Link>
      <Link to="/users-management">Users Management</Link>
      <span style={{cursor: 'pointer'}} onClick={logout}>Logout</span>
    </nav>
  )
}

export default Nav