import { useState } from 'react'

import './UsersManagement.scss'

import UsersList from '../../components/views/UsersList/UsersList'
import UserDetail from '../../components/views/UserDetail/UserDetail'

const UsersManagement = () => {
  const [userId, setUserId] = useState(0)
  
  return (
    <div className="UsersManagement">
      <h1>Users Management</h1>

      <div className="wrapper">
        {
          userId === 0
          ?
          <UsersList setUserId={setUserId} />
          :
          <UserDetail userId={userId} setUserId={setUserId} />
        }
      </div>
    </div>
  )
}

export default UsersManagement