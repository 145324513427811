import { useState, useEffect } from 'react'

import './UserDetail.scss'

import Button from '../../elements/Button/Button'
import { getSubscriptionName } from '../../../utils/formats'
import { fetchItems } from '../../../utils/server'

const UserDetail = ({ userId, setUserId }) => {
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [user, setUser] = useState({})

  useEffect(() => {
    const getUser = async () => {
      fetchItems(
        `user/${userId}`,
        data => data,
        setUser,
        setErrorMsg,
        setError
      )
    }

    getUser()
  }, [userId])

  return (
    <div className="UserDetail">
      <h2>User Details</h2>
      
      {
        Object.keys(user).length > 0
        ?
        <>
          <table>
            <tbody>
              <tr>
                <td>Username</td>
                <td>{user.username}</td>
              </tr>
              <tr>
                <td>First Name</td>
                <td>{user.firstname}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{user.lastname}</td>
              </tr>
              <tr>
                <td>Subscription</td>
                <td>{getSubscriptionName(user.subscription)}</td>
              </tr>
              {
                user.company &&
                <tr>
                  <td>Company</td>
                  <td>{user.company}</td>
                </tr>
              }
              {
                user.department &&
                <tr>
                  <td>Department</td>
                  <td>{user.department}</td>
                </tr>
              }
            </tbody>
          </table>

          { error && <p><span className="bold">Error:</span> <span>{errorMsg}</span></p> }
        </>
        :
        <p>No data</p>
      }

      <div className="buttons">
        <Button text="Back" fn={() => setUserId(0)} classes="cancel" />
      </div>
    </div>
  )
}

export default UserDetail