import {
  SUBSCRIPTION_MONTHLY,
  SUBSCRIPTION_YEARLY,
  SUBSCRIPTION_INACTIVE
} from './constants'

export const getSubscriptionName = subscriptionType =>
  subscriptionType === SUBSCRIPTION_MONTHLY ? 'Monthly' :
  subscriptionType === SUBSCRIPTION_YEARLY ? 'Yearly' :
  subscriptionType === SUBSCRIPTION_INACTIVE ? 'Inactive' :
  '-'