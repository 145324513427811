import './SuccessImport.scss'

import Button from '../../elements/Button/Button'
import { STEPS_DATA_IMPORTER } from '../../../utils/constants'

const SuccessImport = ({ steps, importedData }) => {
  const { previousStep, goToStep } = steps;

  return (
    <div className="SuccessImport">
      <h2>Imported data report</h2>

      <p>{importedData.message}</p>
      
      {
        importedData.createdUsers.length > 0
        &&
        <>
          <h3>Users created successfully</h3>

          <ul className="wrapper-sm">
          {
            importedData.createdUsers.map((user, index) => <li key={index}>{user}</li>)
          }
          </ul>
        </>
      }
      {
        importedData.notCreatedSubscriptions.length > 0
        &&
        <>
          <h3>Users created without valid subscription</h3>

          <ul className="wrapper-sm">
          {
            importedData.notCreatedSubscriptions.map((user, index) => <li key={index}>{user}</li>)
          }
          </ul>
        </>
      }
      {
        importedData.notCreatedUsers.length > 0
        &&
        <>
          <h3>Users not created</h3>

          <ul className="wrapper-sm">
          {
            importedData.notCreatedUsers.map((user, index) =>
              <li key={index}>
                <span className="bold">{user.email}</span><br />
                {user.error?.message ? user.error.message : 'Unknown error'}
              </li>
            )
          }
          </ul>
        </>
      }

      <div className="buttons">
        <Button text="Back" fn={() => goToStep(previousStep[previousStep.length-1])} classes="cancel" />
        <Button text="Go to Start" fn={() => goToStep(STEPS_DATA_IMPORTER.SELECT_USERS_TYPE)} />
      </div>
    </div>
  )
}

export default SuccessImport