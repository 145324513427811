import { useState } from 'react'

import './DataImporter.scss'

import Breadcrumbs from '../../components/layout/Breadcrumbs/Breadcrumbs'
import SelectUsersType from '../../components/views/SelectUsersType/SelectUsersType'
import ChooseItem from '../../components/views/ChooseItem/ChooseItem'
import CreateItem from '../../components/views/CreateItem/CreateItem'
import ImportUsers from '../../components/views/ImportUsers/ImportUsers'
import SuccessImport from '../../components/views/SuccessImport/SuccessImport'
import { STEPS_DATA_IMPORTER } from '../../utils/constants'

const DataImporter = () => {
  const [step, setStep] = useState(STEPS_DATA_IMPORTER.SELECT_USERS_TYPE)
  const [previousStep, setPreviousStep] = useState([])
  const [selectedCompany, setSelectedCompany] = useState({})
  const [selectedDepartment, setSelectedDepartment] = useState({})
  const [importedData, setImportedData] = useState({})

  const goToStep = (step, fromTo = null) => {
    if (step === STEPS_DATA_IMPORTER.SELECT_USERS_TYPE) {
      setSelectedCompany({})
      setSelectedDepartment({})
    }

    if (fromTo !== null) {
      setPreviousStep([...previousStep, fromTo])
    }
    else {
      setPreviousStep(previousStep.filter(item => item < step))
    }
    
    setStep(step)
  }
  
  return (
    <div className="DataImporter">
      <h1>Data Importer</h1>

      <div className="wrapper">
        <Breadcrumbs step={step} goToStep={goToStep} crumbs={[
          { text: 'Users Type', step: STEPS_DATA_IMPORTER.SELECT_USERS_TYPE },
          { text: 'Choose Company', step: STEPS_DATA_IMPORTER.CHOOSE_COMPANY },
          { text: 'Create Company', step: STEPS_DATA_IMPORTER.CREATE_COMPANY },
          { text: 'Choose Department', step: STEPS_DATA_IMPORTER.CHOOSE_DEPARTMENT },
          { text: 'Create Department', step: STEPS_DATA_IMPORTER.CREATE_DEPARTMENT },
          { text: 'Import Users', step: STEPS_DATA_IMPORTER.IMPORT_USERS },
          { text: 'Success Import', step: STEPS_DATA_IMPORTER.SUCCESS_IMPORT }
        ]} />

        { step === STEPS_DATA_IMPORTER.SELECT_USERS_TYPE && <SelectUsersType goToStep={goToStep} /> }
        { step === STEPS_DATA_IMPORTER.CHOOSE_COMPANY &&
          <ChooseItem
            steps={{ previousStep, step, goToStep }}
            types={{ itemType: 'company', itemsType: 'companies' }}
            selections={{ selectedItem: selectedCompany, setSelectedItem: setSelectedCompany }} /> }
        { step === STEPS_DATA_IMPORTER.CREATE_COMPANY &&
          <CreateItem
            steps={{ previousStep, step, goToStep }}
            types={{ itemType: 'company', itemsType: 'companies' }}
            selections={{ setSelectedItem: setSelectedCompany }} /> }
        { step === STEPS_DATA_IMPORTER.CHOOSE_DEPARTMENT &&
          <ChooseItem
            steps={{ previousStep, step, goToStep }}
            types={{ itemType: 'department', itemsType: 'departments' }}
            selections={{ selectedItem: selectedDepartment, setSelectedItem: setSelectedDepartment, selectedParent: selectedCompany }} /> }
        { step === STEPS_DATA_IMPORTER.CREATE_DEPARTMENT &&
          <CreateItem
            steps={{ previousStep, step, goToStep }}
            types={{ itemType: 'department', itemsType: 'departments' }}
            selections={{ setSelectedItem: setSelectedDepartment, selectedParent: selectedCompany }} /> }
        { step === STEPS_DATA_IMPORTER.IMPORT_USERS &&
          <ImportUsers
            steps={{ previousStep, goToStep }}
            selections={{ selectedCompany, selectedDepartment }}
            setImportedData={setImportedData} /> }
        { step === STEPS_DATA_IMPORTER.SUCCESS_IMPORT &&
          <SuccessImport
            steps={{ previousStep, goToStep }}
            importedData={importedData} /> }
      </div>
    </div>
  )
}

export default DataImporter