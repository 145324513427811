import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './Login.scss'

import Button from '../../components/elements/Button/Button'
import EyePassword from '../../components/elements/EyePassword'
import { ERROR_MESSAGE, ERROR_LOGIN_MESSAGE } from '../../utils/constants'
import { fetchData } from '../../utils/server'
import { handleChange } from '../../utils/validations'

const Login = ({signin}) => {
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [errorUsername, setErrorUsername] = useState(false)
  const [errorPassword, setErrorPassword] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate()

  const login = async () => {
    let data

    try {
      data = await fetchData(
        `${process.env.REACT_APP_URL_SERVER}/admin/login`,
        'POST',
        { email: username, password }
      )
      
      if (data?.data?.token) {
        localStorage.setItem('token', data.data.token)
        signin()
        navigate('/data-importer')
      }
      else {
        setErrorMsg(ERROR_LOGIN_MESSAGE)
        setError(true)
      }
    }
    catch (error) {
      if (data?.error?.message) {
        setErrorMsg(data.error.message)
      }
      else {
        setErrorMsg(ERROR_MESSAGE)
      }

      setError(true)
    }
  }

  const validate = () => {
    if (
      username.length > 0 &&
      password.length > 0
    ) {
      login()
    }
    else {
      setErrorUsername(username.length === 0)
      setErrorPassword(password.length === 0)

      setError(true)
      setErrorMsg('Please enter a valid username and password')
    }
  }

  return (
    <div className="Login">
      <h1>Admin Web Tools</h1>

      <div className="wrapper-sm">
        <label>Username</label>
        <input type="text" className={errorUsername ? 'field-error' : ''} placeholder="Enter Username" onChange={e => handleChange(e, setUsername)} />

        <label className="psw-label">
          Password
          <EyePassword showPassword={showPassword} setShowPassword={setShowPassword} />
        </label>
        <input type={!showPassword ? 'password' : 'text'} className={errorPassword ? 'field-error' : ''} placeholder="Enter Password" onChange={e => handleChange(e, setPassword)} />

        { error && <p><span className="bold">Error:</span> <span>{errorMsg}</span></p> }

        <div className="buttons">
          <Button text="Login" fn={() => validate()} />
        </div>
      </div>
    </div>
  )
}

export default Login