import { useState } from 'react'
import { Link, Routes, Route } from 'react-router-dom'

import './App.scss'

import Protected from './components/Protected'
import Header from './components/layout/Header/Header'
import Nav from './components/layout/Nav/Nav'
import Login from './pages/Login/Login'
import DataImporter from './pages/DataImporter/DataImporter'
import UsersManagement from './pages/UsersManagement/UsersManagement'

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false)

  return (
    <div className="App">
      <Header />
      { isSignedIn && <Nav signout={() => setIsSignedIn(false)} /> }
      
      <Routes>
        <Route path="/" element={ <Login signin={() => setIsSignedIn(true)} /> } />
        <Route path="/data-importer" element={
          <Protected isSignedIn={isSignedIn}>
            <DataImporter />
          </Protected>
        } />
        <Route path="/users-management" element={
          <Protected isSignedIn={isSignedIn}>
            <UsersManagement />
          </Protected>
        } />
        <Route path="*" element={
          <div>
            <h2>There is nothing here</h2>
            <p><Link to="/">Go back to the main page</Link></p>
          </div>
        } />
      </Routes>
    </div>
  )
}

export default App